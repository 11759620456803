import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          background: `url(${require("assets/images/7P7A8473.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
        className="section pt-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-primary"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="m-0 my-4 text-g-p text-center text-white"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                  color: "#000 !important",
                }}
              >
                PAST EVENT HIGHLIGHTS
              </h1>
            </Col>
            <Col md={12} className="text-center mt-5">
              <img
                src={require(`assets/images/DT-LOGO-2022.png`)}
                style={{ maxWidth: size ? "450px" : "250px" }}
                width="100%"
                alt="dt22"
              />
              <h4
                className="text-400 mt-0 text-white mt-3 pt-3"
                style={{ borderTop: "1px solid #000" }}
              >
                DUSIT THANI HOTEL, DUBAI
                <br />
                MARCH 21-22, 2022
              </h4>
            </Col>

            <Col lg={12} className={"text-center mt-5"}>
              <img
                src={require(`assets/images/DTSA-2022-LOGO.png`)}
                style={{ maxWidth: size ? "450px" : "250px" }}
                width="100%"
                alt="dt22"
              />
              <h4
                className="text-400 mt-0 text-white mt-3 pt-3"
                style={{ borderTop: "1px solid #000" }}
              >
                RIYADH AIRPORT MARRIOTT HOTEL <br />
                NOVEMBER 8-9, 2022
              </h4>
            </Col>
            <Col lg={12} className="">
              <p className="text-400 text-white"></p>
              <div className="container-fluid row justify-content-center px-0 pl-3">
                {content2.map((c) => (
                  <Col lg={3} xs={3} className="text-center text-white px-1">
                    <img
                      src={require(`assets/icons/${c.image}`)}
                      // style={{ maxWidth: "40px" }}
                      width="100%"
                      alt="dt22"
                    />
                    <h2 className="text-900 text-g-p mt-2" style={{ fontSize: "20px" }}>
                      {c.value}
                    </h2>
                    <p className="text-700 " style={{ fontSize: "12px" }}>
                      {c.title}
                    </p>
                  </Col>
                ))}
              </div>
            </Col>
            <Col lg={12} className="">
              <p className="text-400 text-white"></p>
              <div className="container-fluid row justify-content-center px-0 pl-3">
                {content1.map((c) => (
                  <Col lg={3} xs={3} className="text-center text-white px-1">
                    <img
                      src={require(`assets/icons/${c.image}`)}
                      // style={{ maxWidth: "40px" }}
                      width="100%"
                      alt="dt22"
                    />
                    <h2 className="text-900 text-g-p mt-2" style={{ fontSize: "20px" }}>
                      {c.value}
                    </h2>
                    <p className="text-700 " style={{ fontSize: "12px" }}>
                      {c.title}
                    </p>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content1 = [
  { title: "SPEAKERS", value: 32, image: "p1.png" },
  { title: "ATTENDEES", value: 250, image: "p2.png" },
  { title: "EXHIBITORS", value: 12, image: "p3.png" },
  { title: "WORKSHOP DELEGATES", value: 55, image: "p4.png" },
];

const content2 = [
  { title: "SPEAKERS", value: 34, image: "p5.png" },
  { title: "ATTENDEES", value: 365, image: "p6.png" },
  { title: "EXHIBITORS", value: 14, image: "p7.png" },
  { title: "WORKSHOP DELEGATES", value: 81, image: "p8.png" },
];
