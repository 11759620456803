import React from "react";

// core components
import Navbar from "../components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "../components/Footer.js";

import Agenda from "components/Agenda.js";

function Home() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="Agenda" bgColor="#fff" />
      <Agenda />
      <DemoFooter />
    </>
  );
}

export default Home;
