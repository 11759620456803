import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          background: `url(${require("assets/images/london.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
        className="section pt-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-primary"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={9}>
              <h1
                className="m-0 my-4 text-g-p text-white"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Event
                <br />
                Summary
              </h1>
              <p className="text-400 text-white">
                Saifee Events successfully organised the inaugural UK DIGITAL TWIN 2023 conference
                at the IET London Savoy Place in June 2023 – a dedicated event on the Digital Twins
                ecosystem for the Built Environment sector.
                <br />
                We are thrilled to continue the 2nd Annual edition in London, where His Majesty’s
                Government continues to support the National Digital Twin Programme with investments
                to implement Digital Twins technologies and processes across the UK especially with
                the Digital Twin project development at the Department of Transport (DfT) UK.
                <br />
                <br />
                The <b>2nd Annual UK DIGITAL TWIN 2024 conference on October 30, 2024</b> will have
                focus on exploring the Digital Twin path from Vision to Reality.
                <br />
                <br />
                The conference agenda will raise awareness on Digital Twins, share Government vision
                on their roadmap, bring forth regional and global user case studies, proof of
                concepts, new technologies and applications that result in successful project
                delivery for infrastructure and sustainable initiatives. Workshops on
                <b> October 31, 2024</b> will aim at offering technical knowhow and knowledge
                transfer on Digital Twins for Built Environment and Energy.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
