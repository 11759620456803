import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import Modal from "reactstrap/lib/Modal";
import SpeakerModal from "./SpeakerModal";

function Attend() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  alt="speaker"
                  width="100%"
                  className="rounded-circle p-2"
                  style={{ border: "2px #20bdbe dashed" }}
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center">
                  <h4 className="text-700 mt-2 mb-0 text-g d-block px-0">{speaker.sTitle}</h4>
                  <p className="text-700 mt-2 mb-0 d-block px-0 ">{speaker.name}</p>
                  <p className="text-300 m-0">{speaker.title}</p>
                  <p
                    className="text-400 m-0 d-block px-0"
                    // style={{ fontSize: "16px" }}
                  >
                    {speaker.company}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  const workshops = [
    {
      title: "Connected Digital Twins to Enable Public Good",
      subTitle: "WORKSHOP LEADERS",
      time: "9:00 - 12:00 ",
      speakers: [
        {
          sTitle: "",
          name: "Rahul Shah",
          title: "Sector Director EMEA, Built Environment",
          company: "BSI",
          image: "rahul.png",
          text: [``],
        },
        {
          sTitle: "",
          name: "Dan Rossiter",
          title: "Built Environment Sector Lead",
          company: "BSI",
          image: "1681411655200-DanCurrent-2-2.jpg",
          text: [``],
        },
      ],
    },
    {
      title: "How to build an Asset Twin: An end-to-end hands-on experience",
      subTitle: "WORKSHOP LEADERS",
      time: "13:00 - 15:00",
      speakers: [
        {
          sTitle: "",
          name: "Anand Mecheri",
          title: "CEO",
          company: "Invicara",
          image: "1682420542234-anand_headshot_02-2-2.jpg",
          text: [``],
        },
        {
          sTitle: "",
          name: "Louise Kelly",
          title: "Solutions Consultant, Invicara",
          company: "",
          image: "LouiseKelly.jpg",
          text: [``],
        },
        {
          sTitle: "",
          name: "Vittorio Gragnaniello",
          title: "Development Services Manager, Invicara",
          company: "",
          image: "VittorioGragnaniello.jpg",
          text: [``],
        },
      ],
      sideSpeakers: [],
    },
    {
      title: "Cross Sector Digital Twins: Challenges and Opportunities",
      subTitle: "WORKSHOP LEADER",
      time: "15:00 - 17:00",
      speakers: [
        {
          sTitle: "",
          name: "Paul Surin",
          title: "Built Environment Panel Member",
          company: "IET",
          image: "1682335776214-1666175377412-PaulSurin_1.jpg",
          text: [``],
        },
      ],
    },
  ];

  const speakers = [];
  return (
    <>
      <div
        style={{
          // backgroundImage: "url(" + require("assets/images/bg.jpg") + ")",
          background: "transparent",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container className={""}>
          <Row className="justify-content-md-center">
            <Col md="12">
              <h1
                className=" text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                WORKSHOPS
              </h1>
            </Col>
            {workshops.map((workshop, index) => (
              <>
                <Col xs={12} className="">
                  <div className="text-center">
                    <h2 className="text-700 text-g">
                      WORKSHOP {index + 1} | {workshop.time}
                    </h2>
                    <h3 className="text-700 mb-2 mb-0 text-g d-block px-0">{workshop.title}</h3>
                    <h4 className="text-700 mb-2 mb-0 text-dark d-block px-0">
                      {workshop.subTitle}
                    </h4>
                  </div>
                </Col>
                {workshop.speakers.map((speaker) => (
                  <Col lg={4} xs={12}>
                    <SpeakerCard speaker={speaker} />
                  </Col>
                ))}
                {workshop.sideSpeakers && (
                  <>
                    <Col lg={1} />
                    <Col lg={5} className="mt-5">
                      {workshop.sideSpeakers.map((s) => (
                        <Card
                          className="rounded-0 incomplete-box"
                          style={{
                            background: "transparent",
                            // border: "5px #20bdbe solid",
                          }}
                        >
                          <div className="container-fluid row ml-1 py-2">
                            <Col xs={3} className="align-self-center">
                              <img
                                src={require(`assets/speakers/${s.image}`)}
                                style={{ minWidth: "120px", marginLeft: "-100px" }}
                                width="100%"
                                alt="about"
                                className="rounded-circle"
                              />
                            </Col>
                            <Col xs={9} className="align-self-center">
                              <p
                                className="text-400 mt-2 text-dark"
                                dangerouslySetInnerHTML={{ __html: s.title }}
                              ></p>
                            </Col>
                          </div>
                        </Card>
                      ))}
                    </Col>
                  </>
                )}
              </>
            ))}
            {speakers.map((speaker, index) => (
              <Col className="mx-auto px-0 mb-3" lg={12} xs={12} key={index}>
                <SpeakerCard speaker={speaker} />
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col lg={12} className="text-center px-1 mt-5">
              <Button
                href="/workshop"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
                style={{ background: "#20bdbe" }}
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  To know more & Register
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "#888888",
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

const content = [
  {
    title:
      "<b>David Glennon</b>, Senior Director - Digital Delivery, <b>The Red Sea Development Co, Saudi Arabia</b>",
    image: "David Glennon.jpg",
    text: [
      `David Glennon is the Senior Digital Delivery Director for The Red Sea Development Company (TRSDC) , a closed joint stock company wholly owned by the Public Investment Fund (PIF), Saudi Arabia’s sovereign wealth fund. He oversees the development and implementation of new and innovative digital delivery techniques for a new international luxury tourism destination set along the Red Sea as part of the Kingdom’s Vision 2030. 

Bringing more than 25 years’ experience in the construction industry, he oversees the use of pioneering technology to improve the design and construction process, introducing automation and data driven insights to deliver fast-track construction, design for manufacture and assembly, and enabling the Smart Destination strategy through a ‘Digital Twin’ to enhance customer experience. 

Previous positions include Managing Director of DoubleD.io, a digital accelerator for the built environment, and Head of Digital for Arcadis in the UK, leading their digital programme and the delivery of platforms, innovation, data and analytics and ecosystem thinking. As Director of Digital Project Delivery at AECOM, he was responsible for creating and implementing digital strategy across Europe, the Middle East, India and Africa. Prior to this, David held senior positions at Mott MacDonald and Lend Lease. 
      `,
    ],
  },
  {
    title: "<b>Wajdi Mereb</b>, Senior Manager BIM, <b> MIRAL, UAE</b>",
    image: "Wajdi Mereb (1).jpeg",
    text: [
      `Currently, Wajdi is managing the Digital Twin Initiative & BIM implementation in multi-billion’s projects in one of the most reputable governmental entities in UAE- Miral Asset Management- that has a clear strategy for digital transformation targeting to become a fully  integrated data-driven asset owner. Hence, paving the way to translate 4th Industrial Revolution into reality & integrated with its ecosystem to deliver smart & agile solutions for Leisure & Entertainment industry in Yas Island & beyond.  

      Previously, he initiated suites of smart & intelligent objectives that had been translated into a portfolio of projects in Dubai RTA, which became a regional benchmarking reference that led to a market culture change as far as digital transformation phenomena is concerned, in addition he led RTA to be a key partner in developing BSI’s fundamental BIM Certification schemes “ISO 19650 & UK BIM Level 2”, together with obtaining their certificates as the 1st organization worldwide. Also he managed a successful BIM implementation in multi-billion strategic infrastructure projects “e.g. Metro Route 2020 project- $ 3 billion value” that contributed directly to winning the EXPO 2020 file for UAE. 
      
      `,
    ],
  },
  {
    title: " <b>Andy Boutle</b>, Head of Digital Construction,<b> ALEC, UAE</b>",
    image: "andy.png",
    text: [
      `With over twenty years industry experience, Andy has worked in various engineering and management roles for both consultants and contractors with a more recent specialization in Building Information Modeling (BIM). Known as a vocal proponent for digital transformation across the construction industry, Andy's latest role as Head of Digital Construction has meant a broader focus on ALEC's digitization with an emphasis on increasing workflow communication and vertical efficiencies.

      As the former Head of BIM - Construction Division, Kier Group, Andy's experience enabled him to take a leading role in driving positive industry change, while co-authoring several strategies including "Digital by Default", the Kier Group's Digital Vision and Strategy and Kier's Information Management Function Assignment Matrix, Exchange Information Requirements (EIR) Template and Master Information Delivery Plan (MIDP).
      
      During the same period, Andy also served as the Programme Lead for Kier's ISO 19650 migration as well as being responsible for scoping and maintaining a consistent reporting mechanism for BIM implementation and performance across all construction business units. Under his supervision, Kier Group achieved its Kitemark certification in July 2019, covering construction, infrastructure, highways and design + business services. `,
    ],
  },
  {
    title: "<b>Amr Saad</b>, Associate Director - BIM,<b>ROSHN, Saudi Arabia</b>",
    image: "amr.jpg",
    text: [
      `Amr leads the strategic implementation of digital engineering information management functions for ROSHN Real Estate portfolio. Amr provides insights to decision-makers in the early stages to ensure delivery is aligned with organisational requirements within time and budget and serves the end-users. He had extensive exposure to different delivery phases and practical BIM implementation experience. International bodies appreciate Amr's experience; the Royal Institute of chartered surveyors (RICS) recognised him as a Chartered BIM manager. Additionally, awarded the Expert Elite rank by Autodesk and Project Information Manager / Task Information Manager by the BRE.Amr has a record of accomplishment in transforming international firms from the traditional approaches to the BIM-Data-driven process, enhancing the awareness about digital transformation through conducting workshops, presentations, and training programs to a broad spectrum of audiencesF 
      `,
    ],
  },
  {
    title: "<b>Alex Lubbock</b>, Global Construction Practice Director,<b>BSI, UK</b>",
    image: "Profile Image.jpg",
    text: [``],
  },
];

export default Attend;
