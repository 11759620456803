import React, { useEffect } from "react";

function RegisterPage({ url }) {
  useEffect(() => {
    window.location.href = url;
  }, []);
  return <></>;
}

export default RegisterPage;
