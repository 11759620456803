import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';
import ScrollAnimation from "react-animate-on-scroll";

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="m-0 mb-5 text-g-p text-info"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                KEY TOPICS
              </h1>
            </Col>
          </Row>
          <Row className={` ${size ? "row-cols-5" : "row-cols-2"}`}>
            {content.map((c) => (
              <Col
                className="p-1"
                style={{
                  minHeight: size ? "320px" : "none",
                }}
                key={c.image}
              >
                <div className={`mb-4  px-1 text-dark text-left`}>
                  <img
                    src={require(`assets/icons/${c.image}`)}
                    width="100%"
                    alt="about"
                    style={{ maxWidth: "100px" }}
                    className="mb-3 mx-auto"
                  />
                  <h5 className="text-700 mt-0 ">{c.title}</h5>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={12} className="pr-0">
              <h1
                className="m-0 my-4 text-g text-left"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Conference Format
              </h1>
            </Col>

            {content1.map((c, i) => (
              <Col lg={6} xs={12}>
                <Card
                  className=""
                  style={{
                    background: i % 2 !== 0 ? "#00a8c4" : "#3e3f99",
                    height: size ? "90px" : "none",
                  }}
                >
                  <div className="d-flex p-2 align-items-center">
                    <div className="px-2 align-self-center">
                      <img
                        src={require(`assets/icons/${c.image}`)}
                        style={{ width: "70px" }}
                        width="100%"
                        alt="about"
                        // className="mx-auto"
                      />
                    </div>
                    <p className="text-400 py-2 text-white">{c.title}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

const content = [
  {
    image: "Asset 5.png",
    title: "THE NATIONAL DIGITAL TWIN FRAMEWORK – LESSONS LEARNT AND MOVING AHEAD",
  },

  {
    image: "Asset 4.png",
    title: "EXPLORING THE ECOSYSTEM OF DIGITAL TWINS",
  },
  {
    image: "Asset 3.png",
    title: "ACHIEVING NET ZERO TARGETS VIA SUSTAINABLE METHODS",
  },
  {
    image: "Asset 2.png",
    title: "UNDERSTANDING DIGITAL TWIN IN ITS TRUE SENSE - FROM DESIGN TO HANDOVER OF ASSETS",
  },

  {
    image: "Asset 1.png",
    title:
      "INNOVATIONS FOR DIGITAL TWIN SYSTEMSMETAVERSE, IOT, COMMON DATA ENVIRONMENT, AI, AR/VR, BIM, BLOCKCHAIN",
  },
];

const content1 = [
  {
    title: "VIP KEYNOTES",
    image: "Asset 19.png",
  },
  {
    title: "INTERNATIONAL CASE STUDIES AND PROOF OF CONCEPTS",
    image: "Asset 9.png",
  },
  {
    title: "THOUGHT-PROVOKING PANEL DISCUSSIONS",
    image: "Asset 13 2.png",
  },
  {
    title: "EXHIBITION & NETWORKING ",
    image: "Asset 12.png",
  },
  {
    title: "TECHNICAL-INTERACTIVE WORKSHOPS",
    image: "Asset 10.png",
  },
];
