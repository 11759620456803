// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";
import ParticleBackground from "components/Particles";
// import AnimeBackground from "components/Animecopy";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <div>
      <div
        style={{
          background: `url(${require(`assets/images/${size ? "BG1.png" : "background2.png"}`)})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: size ? "100%" : "cover",
          backgroundAttachment: size ? "fixed" : "scroll",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
        className="page-header"
      >
        {/* <div className="overlay-primary"></div> */}

        <Container style={{ marginTop: !size && "-1rem" }}>
          <Row className="justify-content-center">
            <Col md={6} xs={12}>
              <img
                src={require(`assets/images/logo/DT-UK-LOGO-2024.png`)}
                style={{ maxWidth: size ? "450px" : "300px" }}
                width="100%"
                alt="dt22"
              />
              <h1
                className="text-700 text-dark m-0 mt-3"
                style={{
                  fontSize: size ? "1.60rem" : "1.25rem",
                  // width: size ? "480px" : "330px",
                }}
              >
                <h1
                  className="text-700 text-white text-center m-0 mt-3 mb-3"
                  style={{
                    fontSize: size ? "1.60rem" : "1.25rem",
                    padding: "15px 5px",
                    margin: "10px 0",
                    width: size ? "83%" : "330px",
                    background:
                      "linear-gradient(356deg, rgba(39,53,131,1) 38%, rgba(67,52,139,1) 100%)",
                  }}
                >
                  From Vision To Reality
                </h1>
                <span className="text-info">CONFERENCE</span> - OCTOBER 30, 2024
                <br />
                <span className="text-info">WORKSHOPS</span> - OCTOBER 31, 2024
                <br />
                <br />
                <span className="text-info ">
                  ONE GREAT GEORGE STREET
                  <br />
                  LONDON, UK
                </span>
                <br />
                <br />
                <div style={{}}></div>
              </h1>

              <div className="container-fluid row mt-1">
                <Col lg={12} className="px-0">
                  <Button
                    href="/register"
                    className="rounded-0 navbar-item-custom text-400 text-white px-4"
                    color="primary"
                    size="lg"
                    target="_blank"
                    style={{ background: "#3e3f99" }}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: "#fff",
                        textTransform: "none",
                        fontSize: "20px",
                        fontWeight: 700,
                      }}
                    >
                      REGISTER NOW
                    </span>
                  </Button>{" "}
                  <Button
                    href="/agenda"
                    className="rounded-0 navbar-item-custom text-400 text-white px-4"
                    color="primary"
                    size="lg"
                    target="_blank"
                    style={{ background: "#3e3f99" }}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: "#fff",
                        textTransform: "none",
                        fontSize: "20px",
                        fontWeight: 700,
                      }}
                    >
                      VIEW AGENDA
                    </span>
                  </Button>
                </Col>
              </div>
            </Col>
            {/* <Col md={6} xs={12} className="mt-4"></Col> */}
          </Row>
        </Container>
        {/* <AnimeBackground /> */}
        <ParticleBackground />
      </div>
    </div>
  );
}

export default LandingPageHeader;
