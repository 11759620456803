import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Container, Row, Col } from "reactstrap";
import { apiUrl, newToken } from "../constants";
import { FaEnvelope, FaPhone } from "react-icons/fa";

function QRView() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const type = urlParams.get("t");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getDelegateData = () => {
    setLoading(true);
    fetch(`${apiUrl}/getDelegateData/${id}`, {
      method: "get",
      headers: new Headers({
        Authorization: newToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const [row] = (data && data.rows) || [{}];
        const delegate = {
          name: `${row.firstName} ${row.lastName}`,
          title: row.title,
          company: row.company,
          country: row.country,
          email: row.email,
          phone: row.phone,
          id: row.id,
          shortId: row.shortId,
          type: row.delegateType,
        };
        setData(delegate);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const getSpeakerData = () => {
    setLoading(true);
    fetch(`${apiUrl}/getSpeakerData/${id}`, {
      method: "get",
      headers: new Headers({
        Authorization: newToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const [row] = (data && data.rows) || [{}];
        const speaker = {
          name: row.name,
          title: row.title,
          company: row.company,
          country: row.country,
          email: row.email,
          phone: row.phone,
          id: row.id,
          shortId: row.shortId,
          type: "SPEAKER",
        };
        setData(speaker);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (type === "s") {
      getSpeakerData();
    } else {
      getDelegateData();
    }
  }, [type]);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      <div
        className="section section-with-space border-bottom"
        style={{
          background: `url(${require(`assets/images/rie9.jpg`)})`,
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
        }}
        data-parallax={true}
      >
        {data.id ? (
          <>
            <Container className="">
              <Row className="justify-content-center">
                <div
                  className="col-lg-4 text-center"
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                  <img
                    src={require(`assets/images/logo/DT-UK-LOGO-2024.png`)}
                    width="100%"
                    style={{ maxWidth: 200 }}
                    alt="logo"
                  />
                  <div className="text-center">
                    <div className="mt-5">
                      <h1 className="text-700 mt-0 text-g">{data.name}</h1>
                      <h3 className=" text-700 mt-0">{data.company}</h3>
                      <br />
                      <p className="mt-0">
                        <button
                          onClick={() => handleCopy(data.email)}
                          target="_blank"
                          class="btn btn-primary mx-2"
                        >
                          <FaEnvelope style={{ fontSize: "1.2rem" }} />
                        </button>
                        {data.phone && (
                          <button
                            target="_blank"
                            class="btn btn-primary mx-2"
                            onClick={() => handleCopy(data.phone)}
                          >
                            <FaPhone style={{ fontSize: "1.2rem" }} />
                          </button>
                        )}
                      </p>
                    </div>
                    <div className="py-4" style={{ background: "white" }}>
                      <QRCode value={`${apiUrl}/${data.shortId}/${type}`} size={150} />
                    </div>
                    <div style={{}}>
                      <h1
                        className="text-400 text-white text-center m-0 mt-3 mb-3"
                        style={{
                          fontSize: "2.25rem",
                          padding: "15px 5px",
                          background:
                            "linear-gradient(356deg, rgba(39,53,131,1) 38%, rgba(67,52,139,1) 100%)",
                        }}
                      >
                        {`${data.type}`}
                      </h1>
                    </div>
                  </div>
                </div>
                {/*  */}
              </Row>
            </Container>
          </>
        ) : (
          <h1
            className="text-700 text-center text-g mb-3"
            style={{
              fontSize: "3rem",
            }}
          >
            {loading ? "LOADING ...." : `No Data`}
          </h1>
        )}
      </div>

      <footer className="footer" style={{ background: "#fff" }}>
        <Container className="py-3">
          <Row className="justify-content-center">
            <Col lg={4} xs={8} className="text-center"></Col>
          </Row>
          <Row className="justify-content-center pt-4">
            <Col lg={2} xs={5} className="text-center">
              <p className="text-400 text-dark">Organised By</p>
              <a href="/">
                <img src={require("assets/images/logo/3.png")} alt="vibe" width="100%" />
              </a>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col xs={12} className={"text-center text-dark pt-10"}>
              <hr />
              <p>Copyright © Saifee-Events {new Date().getFullYear()}. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default QRView;
