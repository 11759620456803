import { DataContext } from "DataContainer";
import moment from "moment";
import React, { useContext } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  Card,
  Modal,
} from "reactstrap";
import { groupBy } from "views/content";
import SpeakerModal from "./SpeakerModal";

function Agenda() {
  const [activeTab, setActiveTab] = React.useState("1");

  const { agenda, speakers: allSpeakers } = useContext(DataContext);
  const dayWiseAgenda = groupBy("day")(agenda);
  const speakers = allSpeakers.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});

  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  function SpeakerCard({ speaker }) {
    return (
      <>
        <div className={`w-${size ? 50 : 100} text-left py-${size ? 4 : 2}`}>
          <a href="/" onClick={(e) => updateModal(e, speaker)}>
            <img
              src={speaker.image}
              alt="speaker"
              width="100%"
              className="rounded-circle p-2 mx-auto"
              style={{ border: "2px #20bdbe dashed", maxWidth: 100 }}
            />
          </a>
          <div className="">
            <h4 className="text-700 mt-2 mb-0 d-block px-0">{speaker.sTitle}</h4>
            <p className="text-700 text-g  mt-2 mb-0 d-block px-0 " style={{ fontSize: "16px" }}>
              {speaker.name}
            </p>
            <p className="text-300 m-0" style={{ fontSize: "16px" }}>
              {speaker.title}
            </p>
            <p className="text-400 m-0 d-block px-0 text-i" style={{ fontSize: "16px" }}>
              {speaker.company}
            </p>
          </div>
        </div>
      </>
    );
  }

  function AgendaCard({ data }) {
    const size =
      document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
        ? true
        : false;

    return data.map((d, index) => (
      <Card className="p-2">
        <Container>
          <Row>
            <Col lg={2} className="align-self-start ">
              <h4 className="text-600 mt-0 text-g">
                <b>
                  {moment.utc(d.startAt).format("HH:mm")} - {moment.utc(d.endAt).format("HH:mm")}{" "}
                </b>{" "}
              </h4>
            </Col>
            <Col lg={10} className="align-self-start ">
              <h4 className="text-400 m-0 text-g">
                <b>
                  {d.type ? `${d.type} - ` : ""}
                  {d.title}
                </b>
              </h4>
              {d.description && (
                <p
                  className="text-400 text-dark pt-3"
                  dangerouslySetInnerHTML={{ __html: d.description }}
                />
              )}
              <div className="container row">
                {d.type === "Panel Discussion" && <SpeakerCard speaker={speakers[d.moderator]} />}
                {d.speakers &&
                  d.speakers.map((speaker) => <SpeakerCard speaker={speakers[speaker]} />)}
              </div>
            </Col>
          </Row>
        </Container>
      </Card>
    ));
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          // backgroundImage: "linear-gradient(315deg, #ffffff 0%, #1b2845 74%)",

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="section py-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col md="12">
              <div className="nav-tabs-navigation text-left mt-3 mb-3">
                <div className="nav-tabs-wrapper">
                  <Nav tabs className="nav-fill">
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 ${activeTab === "1" ? "active" : ""}`}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <h5 className="text-400 my-0 pl-3 text-center">
                          <b>CONFERENCE</b>
                        </h5>
                        <p className="text-400 my-0 pl-3 text-center">30 OCTOBER</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 ${activeTab === "2" ? "active" : ""}`}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <h5 className="text-400 my-0 pl-3 text-center">
                          <b>WORKSHOP</b>
                        </h5>
                        <p className="text-400 my-0 pl-3 text-center">31 OCTOBER</p>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col md="12">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[1] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[2] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="py-3 mb-5 text-center">
              <Button
                href="/request-agenda"
                className="navbar-item-custom text-400 text-dark rounded-0 btn btn-info "
                size="lg"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className="text-600"
                >
                  REQUEST FULL AGENDA
                </span>
              </Button>
            </Col>
            {/* <Col lg={12} className="pt-3 text-center"></Col> */}
          </Row>
        </Container>
      </div>
      <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
        <div className="section profile-content py-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => updateModal(e)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#888888",
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <SpeakerModal speaker={open.speaker} />
        </div>
      </Modal>
    </>
  );
}

export default Agenda;
