import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function Attend() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          // backgroundImage: "url(" + require("assets/images/bg.jpg") + ")",
          backgroundColor: "transparent",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container className={""}>
          <Row>
            <Col md="12">
              <h1
                className=" text-left text-g mb-5 text-dark"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                WHO CAN PARTICIPATE
              </h1>
            </Col>
            {content.map((c, i) => (
              <Col lg={4} xs={12} className="px-1">
                <Card
                  className=""
                  style={{
                    background: i % 2 !== 0 ? "#00a8c4" : "#3e3f99",
                    height: size ? "90px" : "none",
                  }}
                >
                  <div className="d-flex p-2 align-items-center">
                    <div className="px-2 align-self-center">
                      <img
                        src={require(`assets/icons/${c.image}`)}
                        style={{ width: "70px" }}
                        width="100%"
                        alt="about"
                        // className="mx-auto"
                      />
                    </div>
                    <p className="text-400 py-2 text-white">{c.title}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1 mt-5">
              <Button
                href="/register"
                className="rounded-0 text-400 text-white"
                color="info"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
                style={{ background: "#3e3f99" }}
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  REGISTER NOW
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: "PROPERTY DEVELOPERS", image: "Asset 18.png" },
  { title: "PROJECT OWNERS", image: "Asset 19.png" },
  { title: "ARCHITECTURE, ENGINEERING, CONTRACTORS", image: "Asset 17.png" },
  { title: "CONSTRUCTION MANAGEMENT CONSULTANTS", image: "Asset 16.png" },
  { title: "GIS LEADERS", image: "Asset 14.png" },
  { title: "GOVERNMENT BODIES & MUNICIPALITIES", image: "Asset 15.png" },
  { title: "IT LEADERS", image: "Asset 21.png" },
  { title: "CITY PLANNERS", image: "Asset 23.png" },
  { title: "FACILITY MANAGERS", image: "Asset 22.png" },
  { title: "DIGITAL TWIN SOLUTION PROVIDERS", image: "Asset 8.png" },
  { title: "METAVERSE OFFICERS", image: "Asset 7.png" },
  { title: "DATA MANAGEMENT LEADERS", image: "output-onlinepngtools.png" },
  { title: "BIM & DIGITAL CONSTRUCTION SOLUTION PROVIDERS", image: "Asset 20.png" },
];

export default Attend;
