import React from "react";

// core components
import Navbar from "../components/Navbar.js";
// import MobileNavbar from "../components/Navbars/MobileNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "../components/Footer.js";
import TextGrid from "../components/TextGrid.js";

import AboutPoints from "../components/AboutPoints.js";

import Sponsor from "../components/Sponsor.js";
// import ImageGrid from "../components/ImageGrid.js";
import Workshop from "../components/Workshop.js";
import Photos from "../components/Photos.js";
import Speaker from "../components/SpeakerCard";
import PastEventHighlights from "../components/PastEventHighlights";
import PastEventHighlights1 from "../components/PastEventHighlights1";
import Profile from "../components/Profile";
import PastSponsor from "../components/PastSponsor";

import Attend from "components/Attend.js";
import AnimeBackground from "components/Animecopy.js";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <Navbar />
      <LandingPageHeader />
      {/* {size ? <PastEventHighlights1 /> : <PastEventHighlights />} */}
      {/* <Photos /> */}
      <section id="about">
        <TextGrid />
      </section>
      {/* <Profile /> */}
      <div
        id="attend"
        style={{
          background: `url(${require(`assets/images/FlowDesignSVG.png`)})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: size ? "100%" : "cover",
          backgroundAttachment: size ? "fixed" : "scroll",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
      >
        <Attend />
        <AboutPoints />
        <section id="speakers" />
        <Speaker />
        {/* <section id="workshop" />
        <Workshop /> */}
        <section id="partners" />
        <Sponsor />
        <PastSponsor />
        <DemoFooter />
      </div>
    </>
  );
}

export default Home;
